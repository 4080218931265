<template>
  <div id="app">
    <div class="bg-white">
      <main>
        <NavBar />
        <HeroSection>
          <h1
            class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl"
          >
            <span class="block xl:inline">Computational Fluid Dynamics</span>
            <span class="block text-indigo-600 xl:inline">
              in your Web-browser</span
            >
          </h1>
          <p
            class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
          >
            WebAssembly brings a new computational paradigm between on desktop
            and in the cloud CFD. This website experiments with this new
            technology to look into what a future may look like.
          </p>
        </HeroSection>

        <!-- Disclaimer - Start -->
        <SectionDivider class="mt-6" label="Disclaimer" />
        <div class="relative py-4 bg-white overflow-hidden">
          <div
            class="relative px-4 sm:px-6 lg:px-8 max-w-prose mx-auto text-sm text-gray-500 leading-normal"
          >
            <p>
              The views/opinions expressed in this website are my own. The
              information is provided as is in the hope that it will be useful
              but without any warranty.
            </p>
          </div>
        </div>
        <!-- Disclaimer - End -->

        <!-- Introduction - Start -->
        <SectionDivider class="mt-6" label="Introduction" />
        <div class="relative pt-8 bg-white overflow-hidden">
          <div
            class="relative px-4 sm:px-6 lg:px-8 max-w-prose mx-auto text-xl text-gray-500 leading-relaxed"
          >
            <p>
              Computational Fluid Dynamics, aka CFD, refers to the area of
              science aiming to predict the movement of fluid using computer
              simulation. CFD has a reasonably long history with strong links
              with changes in computational power.
            </p>
            <p class="mt-5">
              My experience has ranged from vector supercomputer in an academic
              setting, linux clusters and high-end workstations in industrial
              settings, and cloud computing for personal uses. I am very
              interested in the development of cloud computing in general, and
              cloud computing for computational fluid dynamics, over the last
              decade. It allows for <em>democratisation</em> of the access to
              computational fluid dynamics' tools and particularly alleviate the
              requirement for powerful computer.
            </p>
            <p class="mt-5">
              This website explores WebAssembly and its application to the field
              of computational fluid dynamics and fluid dynamics, in general.
              WebAssembly allows for code to run through a web-browser at speed
              close to native speed, supposedly. I am keen to understand whether
              this paradigm may lead to a different way of delivering CFD codes,
              changes in practices and potentially new or different business
              models. In the first instance, this website provides links and
              examples of fluid dynamics software running in the web-browser.
            </p>

            <div class="text-right mt-5 px-4">
              <a href="/vpm/index.html" type="button" class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Launch Vortex Simulation</a>
            </div>

          </div>
        </div>
        <!-- Introduction - End -->
      </main>

      <FooterSection />
    </div>
  </div>
</template>

<script>
import FooterSection from "@/components/FooterSection.vue";
import HeroSection from "@/components/HeroSection-angledimage.vue";
import NavBar from "@/components/NavBar-simple.vue";
import SectionDivider from "@/components/SectionDivider.vue";

export default {
  name: "App",
  components: {
    FooterSection,
    HeroSection,
    NavBar,
    SectionDivider,
  },
};
</script>

