<template>
  <div class="relative max-w-4xl mx-auto">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-gray-300"></div>
    </div>
    <div class="relative flex justify-center">
      <span class="px-2 bg-white text-sm text-gray-500">
        {{ label }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionDivider",
  data: () => ({}),
  props: ["label"],
};
</script>
