<!-- Footer section extracted from https://tailwindui.com/components/marketing/page-examples/landing-pages#component-c574f863382921bf9af9611c55f30ecc -->
<template>
  <div class="footer_section">
    <!-- Footer section -->
    <footer class="mt-12 bg-gray-900 sm:mt-6">
      <div class="flex mx-auto py-6 px-4 overflow-hidden sm:px-6 lg:px-8">
        <div class="flex-1">
          <p class="text-base text-gray-400">
            &copy; 2021 Julien de Charentenay
          </p>
        </div>
        <div class="flex-1">
          <p class="text-right text-base text-gray-400">
            <a href="https://www.charentenay.me"
              >Visit charentenay.me for more projects</a
            >
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "FooterSection",
  props: {},
};
</script>
